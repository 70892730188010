* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --main-blue: #9ab6e4;
  --nav-width: 35%;
}

p {
  font-family: 'Roboto Condensed', sans-serif;
}

@media only screen and (max-width: 900px) {
  :root {
    --nav-width: 28%;
  }
}